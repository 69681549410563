import React, { useState } from "react";
import Axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import "./StyleCard.css";

export default function NewPropertyCard({ propertyDetails, refreshPage }) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/homeinfor/${propertyDetails._id}`, {
      state: { property: propertyDetails },
    });
  };

  const currentTime = new Date(); // Get the current time
  const adTime = new Date(propertyDetails.postedAt); // Convert ad's time to a Date object

  // Calculate the time difference in minutes
  const timeDiffInMilliseconds = Math.abs(currentTime - adTime);
  const timeDiffInMinutes = Math.floor(timeDiffInMilliseconds / (1000 * 60));

  // Check if the time difference is more than 60 minutes
  let timeDiffFormatted;
  if (timeDiffInMinutes === 0) {
    timeDiffFormatted = "Just now";
  } else if (timeDiffInMinutes >= 60) {
    const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
    if (timeDiffInHours >= 24) {
      const timeDiffInDays = Math.floor(timeDiffInHours / 24);
      timeDiffFormatted = `${timeDiffInDays} days ago`;
    } else {
      timeDiffFormatted = `${timeDiffInHours} hrs ago`;
    }
  } else {
    timeDiffFormatted = `${timeDiffInMinutes} mins ago`;
  }

  function removeTrailingZeros(value) {
    let stringValue = value.toString();

    // Remove trailing zeros after the decimal point
    if (stringValue.includes(".")) {
      stringValue = stringValue.replace(/\.?0*$/, "");
    }

    return stringValue;
  }

  let totalPrice = propertyDetails.price;
  let formattedPrice;

  if (totalPrice >= 1000000) {
    formattedPrice =
      removeTrailingZeros((totalPrice / 1000000).toFixed(2)) + "M";
  } else if (totalPrice >= 100000) {
    formattedPrice =
      removeTrailingZeros((totalPrice / 100000).toFixed(2)) + " Lakhs";
  } else {
    formattedPrice = removeTrailingZeros(totalPrice.toFixed(2));
  }

  return (
    <Card
      className="propertyD-card"
      onClick={handleCardClick}
      style={{ cursor: "pointer" }}
    >
      <div style={{ position: "relative" }}>
        <CardMedia
          component="img"
          height="205"
          image={propertyDetails.images[0]}
        />
        <div
          style={{
            position: "absolute",
            top: 5,
            left: 5,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            color: "white",
            borderRadius: "8px",
            padding: "4px 8px",
          }}
        >
          <Typography variant="caption">Posted {timeDiffFormatted}</Typography>
        </div>
      </div>

      <CardContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography gutterBottom sx={{ fontSize: "18px", fontWeight: 550 }}>
            Rs. {formattedPrice}
          </Typography>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: propertyDetails.isActive ? "#2196f3" : "red",
                marginRight: "4px",
              }}
            />
            <span
              style={{
                color: propertyDetails.isActive ? "#2196f3" : "red",
                fontSize: "14px",
              }}
            >
              {propertyDetails.isActive ? "Active" : "Off Market"}
            </span>
          </div> */}
        </div>
        <Typography
          style={{
            fontSize: "15px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <span>{propertyDetails.bedrooms} bds</span>
          <span>|</span>
          <span>{propertyDetails.bathrooms} bth</span>
          <span>|</span>
          <span>{propertyDetails.perches} perch</span>
          <span>|</span>
          <span>{propertyDetails.sqft} sqft</span>
        </Typography>

        <Typography
          gutterBottom
          style={{ display: "flex", flexDirection: "column", fontSize: "15px" }}
        >
          <span>
            {propertyDetails.hideAddress
              ? propertyDetails.address.split(" ").slice(1).join(" ")
              : propertyDetails.address}
          </span>
          {/* <span>{propertyDetails.city} - {propertyDetails.propertyType}</span> */}
        </Typography>
      </CardContent>
    </Card>
  );
}
